<template>
  <div class="mobile-width">
    <apexchart
      :options="chartData.chartOptions"
      :series="chartData.chartSeries"
      :width="chartData.width"
      :height="chartData.height"
      :key="chartKey"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  data() {
    return {
      chartKey: 0,
    };
  },
  components: {
    apexchart: VueApexCharts
  },
  
  watch: {
    chartData: {
      handler() {
        this.chartKey += 1;
      },
      deep: true
    },
  },
  props: {
    chartData: {
      required: true
    }
  },
 
};
</script>

<style scoped>
/* @media only screen and (min-device-width: 300px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .mobile-width {
    width: 500px;
  }
} */
</style>
